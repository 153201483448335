<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--5">
            <div class="row">
                <div class="col-8">
                    <div class="card mb-4 bg-secondary shadow">
                        <div class="card-header">
                            <div class="row">
                                <div class="col">
                                    <h3 class="mb-0">{{ tt('my_profile') }}</h3>
                                </div>
                                <div class="col text-right" v-if="dataUserProfile.created_by == 'SMARTCAT'">
                                    <base-button size="sm" type="danger" @click="update" :disabled="btnUpdate.onLoading">{{ tt('update') }}</base-button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <base-alert type="danger" v-if="errorMessage">
                                <p><strong>{{ errorMessage.message }}</strong></p>
                                <div v-if="errorMessage.data.length != 0">
                                    <span v-for="message in errorMessage.data">
                                        {{ message[0] }}<br>
                                    </span>
                                </div>
                            </base-alert>
                            <h6 class="heading-small text-muted mb-4">{{ tt('user_information') }}</h6>
                            <div class="row ml-3">
                                <div class="col-12">
                                    <base-input :label="tt('name')" :name="tt('name')" :placeholder="tt('name')" v-model="dataUserProfile.name" :disabled="disabled"></base-input>
                                </div>
                                <div class="col-6">
                                    <base-input :label="tt('username')" :name="tt('username')" :placeholder="tt('username')" v-model="dataUserProfile.username" :disabled="disabled"></base-input>
                                </div>
                                <div class="col-6">
                                    <base-input :label="tt('email_address')" :name="tt('email_address')" :placeholder="tt('email_address')" v-model="dataUserProfile.email" :disabled="disabled"></base-input>
                                </div>
                            </div>
                            <hr>

                            <h6 class="heading-small text-muted mb-4">{{ tt('other_information') }}</h6>
                            <div class="row ml-3">
                                <div class="col-6">
                                    <base-input :label="tt('client')" :name="tt('client')" :placeholder="tt('client')" v-model="get_client" readonly></base-input>
                                </div>
                                <div class="col-6">
                                    <label class="form-control-label mt-1">{{ tt('company') }}</label>
                                    <el-select class="col-12 ml--3" v-model="dataUserProfile.company" placeholder="Select" disabled>
                                        <el-option
                                        v-for="cc in companyCode"
                                        :key="cc.company_code"
                                        :label="cc.company_code +' - '+ cc.company_description"
                                        :value="cc.company_code">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="row ml-3">
                                <div class="col-6">
                                    <base-input :label="tt('department')" :name="tt('department')" :placeholder="tt('department')" v-model="dataUserProfile.department" readonly></base-input>
                                </div>
                                <div class="col-6">
                                    <base-input :label="tt('section')" :name="tt('section')" :placeholder="tt('section')" v-model="dataUserProfile.section" readonly></base-input>
                                </div>
                            </div>
                            <div class="row ml-3">
                                <div class="col-6">
                                    <label class="form-control-label">Roles</label>
                                    <br>
                                    <label class="badge badge-primary" v-for=" role in dataUserProfile.roles ">{{ role }}</label>
                                </div>
                                <div class="col-6">
                                    <label class="form-control-label">Catalog type</label>
                                    <br>
                                    <label class="badge badge-primary" v-for=" catalog in dataUserProfile.cat_type ">{{ catalog.code }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card card-profile shadow">
                        <div class="card-profile-image">
                          <a href="#">
                            <img :src="loadAvatar(dataUserProfile.image)" class="rounded-circle" height="170" width="170">
                          </a>
                        </div>
                        <div class="card-body pt-0 pt-md-4">
                          <div class="row">
                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                
                              </div>
                            </div>
                          </div>
                          <div class="text-center">
                            <h3 class="mt-4">
                              {{ dataUserProfile.name }}
                            </h3>
                            <div class="h5">
                              <base-button size="sm" type="default" @click="changePhoto">{{ tt('change_photo') }}</base-button>
                            </div>
                            <hr class="my-4">
                          </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('change_photo') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('profile_photo') }} <span class="text-danger">*</span></label>
                <file-input @change="changeProfilePhoto" initialLabel="Image"></file-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary" @click="upload" :disabled="btnUpdatePhoto.onLoading">
                    <span>{{ tt('update') }}</span>
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import userProfile from '@/services/userProfile/userProfile.service';
    import config from '@/configs/config';
    import companyCode from '@/services/master/companyCode.service';

    export default {        
        data() {
            return {    
                disabled: true,  
                btnUpdate: {
                    onLoading: false
                },
                btnUpdatePhoto: {
                    onLoading: false
                },       
                form: {
                    show: false
                },                      
                dataUserProfile: {
                    id: '',
                    username: '',
                    name: '',
                    email: '',
                    company: '',
                    department: '',
                    section: '',
                    roles: {},
                    cat_type: '',
                    image: '',
                    created_by: '',
                },
                inputUserProfile: {
                    image: ''
                },
                errorMessage: null,
                get_client:'',
                companyCode: [],
              
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.get_holding()
            this.get_company()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, userProfile.index()).onSuccess(function(response) {                    
                    context.dataUserProfile.id         = response.data.data.user.user.id;
                    context.dataUserProfile.username   = response.data.data.user.user.username;
                    context.dataUserProfile.name       = response.data.data.user.user.name;
                    context.dataUserProfile.email      = response.data.data.user.user.email
                    context.dataUserProfile.company    = response.data.data.user.user.company_code;
                    context.dataUserProfile.roles      = response.data.data.user.userRole;    
                    context.dataUserProfile.cat_type   = response.data.data.cat_type;    
                    context.dataUserProfile.image      = response.data.data.user.user.image;
                    context.dataUserProfile.department = response.data.data.user.user.department;
                    context.dataUserProfile.section    = response.data.data.user.user.section;
                    context.dataUserProfile.created_by = response.data.data.user.user.created_by;
                    context.checkCreatedBy(response.data.data.user.user.created_by);   
                }).call()
            },
            update() { 
                let context = this;          
                this.btnUpdate.onLoading = true;  
                this.errorMessage = null;   
                Api(context, userProfile.update(context.dataUserProfile.id, context.dataUserProfile)).onSuccess(function(response) {                        
                    context.dataUserProfile.name          = response.data.data.name;
                    context.dataUserProfile.username      = response.data.data.username;
                    context.dataUserProfile.email_address = response.data.data.email;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                }).call()
            },
            get_holding(){
                let context = this; 
                Api(context, userProfile.get_client()).onSuccess(function(response) {                        
                    context.get_client = response.data.data.data.data[0].client_name;              
                }).call()
            },
            changePhoto() {
                this.form.show = true;
            },
            changeProfilePhoto(files) {
                this.inputUserProfile.image = files[0]                
            },
            upload() {
                let context = this;     
                this.btnUpdatePhoto.onLoading = true;           
                let formData = new FormData(); 
                formData.append('image', context.inputUserProfile.image)                
                Api(context, userProfile.change_profile_photo(context.dataUserProfile.id, formData)).onSuccess(function(response) {    
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onFinish(function() {
                    context.btnUpdatePhoto.onLoading = false;
                }).call()
            },
            loadAvatar(image) {
               return config.storageUrl+'images/image_user/'+image;
            },
            checkCreatedBy(created_by){
                if (created_by == "SMARTCAT") 
                {
                    this.disabled = false
                }else{
                    this.disabled = true
                }
            },
            get_company() {
                let context = this;               
                Api(context, companyCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.companyCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.companyCode = [];
                    }
                })
                .call()
            },
        },
    };
</script>
<style></style>
